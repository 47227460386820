import React from 'react'
import { Link } from 'gatsby'

import { Button } from '@bscs-dev-team/bscs-design-system-core'

import { Highlight, Snippet } from 'react-instantsearch-dom'

import classroomInstruction from '../../images/educator-resource-center/classroom-instruction.svg'
import professionalLearning from '../../images/educator-resource-center/professional-learning.svg'
import leadershipWork from '../../images/educator-resource-center/leadership-work.svg'
import citizenScience from '../../images/educator-resource-center/citizen-science.svg'

const icons = {
  'Professional Learning': {
    icon: professionalLearning,
    color: 'rgba(255, 159, 0, 1)'
  },
  'Teacher Professional Learning': {
    icon: professionalLearning,
    color: 'rgba(255, 159, 0, 1)'
  },
  'Classroom Instruction': {
    icon: classroomInstruction,
    color: 'rgba(94, 60, 124, 1)'
  },
  'Field-Test Opportunities': {
    icon: classroomInstruction,
    color: 'rgba(94, 60, 124, 1)'
  },
  'Leadership Work': {
    icon: leadershipWork,
    color: 'rgba(174, 37, 38, 1)'
  },
  'Leadership Development': {
    icon: leadershipWork,
    color: 'rgba(174, 37, 38, 1)'
  },
  'Citizen Science': {
    icon: citizenScience,
    color: 'rgba(17, 151,98, 1)'
  }
}

const getIcon = (category, icons) => {
  return category in icons
    ? icons[category]
    : false
}

const getHitIcons = (hit) => {
  let hit_icons = []

  if (
    'frontmatter' in hit
    && hit.frontmatter.status === 'Ready'
  ) {
    if (hit.frontmatter.type) {
      let icon = getIcon(hit.frontmatter.type, icons)
      if (icon) {
        hit_icons.push(icon)
      }

      return hit_icons
    }

    if (hit.frontmatter.areas) {
      for (let i = 0; i < hit.frontmatter.areas.length; i++) {
        let icon = getIcon(hit.frontmatter.areas[i], icons)

        if (icon) {
          hit_icons.push(icon)
        }
      }
    }
  }

  return hit_icons
}

const HitIcons = ({hit_icons, title}: { hit_icons: any[], title: string}) => {
  console.log(hit_icons)
  if (hit_icons && hit_icons.length === 1) {
   return (
     <img
       className="p-2 mr-1"
       style={{
         width: '75px',
         border: `3px solid ${hit_icons[0].color}`,
         borderRadius: '50%'
       }}
       src={hit_icons[0].icon}
     />
   )
  }

  return (
    <div className="inline-flex">
      {hit_icons.map((icon, index) => {
        return (
          <img
            key={`hit_icons-${title}-${index}`}
            className="p-2 mr-1"
            style={{
              width: '75px',
              border: `3px solid ${icon.color}`,
              borderRadius: '50%'
            }}
            src={icon.icon}
          />
        )
      })}
    </div>
  )
}

const Hit = ({ hit }) => {
  const hit_icons = getHitIcons(hit)
  console.log(hit_icons)

  if ('frontmatter' in hit && hit.frontmatter.status === 'Draft') {
    return <React.Fragment />
  }

  return (
    <div className="flex flex-wrap mt-5">
      {'frontmatter' in hit &&
        <React.Fragment>
          {hit.frontmatter.title &&
            <div className="w-full">
              <h4 className="text-bscs-gray-800 font-semibold text-lg tracking-normal">
                <Highlight
                  attribute="frontmatter.title"
                  hit={hit}
                  tagName="span"
                />
              </h4>
              <div className="border-t-2 border-bscs-yellow-800 w-12" />
            </div>
          }
          {hit.frontmatter.gradeLevel &&
            <p className="w-full">
              <span>
                <strong>
                  Grade Level:&nbsp;
                </strong>
              </span>
              <Highlight
                attribute="frontmatter.gradeLevel"
                hit={hit}
                tagName="span"
              />
            </p>
          }
          {hit.frontmatter.discipline &&
            <p className="w-full">
              <span>
                <strong>
                    Discipline:&nbsp;
                </strong>
              </span>
              <Highlight
                attribute="frontmatter.discipline"
                hit={hit}
                tagName="span" />
              </p>
          }
          {hit.frontmatter.fullName &&
            <p className="w-full">
              <span>
                <strong>
                  Full Name:&nbsp;
                </strong>
              </span>
              <Highlight
                attribute="frontmatter.fullName"
                hit={hit}
                tagName="span"
              />
            </p>
          }
          {hit.frontmatter.canonicalUrl &&
            <div className="flex mt-3 w-full">
              {hit_icons.length > 0 &&
                <HitIcons hit_icons={hit_icons} />
              }
              <a href={hit.frontmatter.canonicalUrl} className="ml-auto">
                <Button title="Go to Page">Go to Page</Button>
              </a>
            </div>
          }
        </React.Fragment>
      }
      {hit.title &&
        <div className="w-full">
          <h4 className="text-bscs-gray-800"><Highlight attribute="title" hit={hit} tagName="span" /></h4>
          <div className="border-t-2 border-bscs-yellow-800 w-12" />
        </div>
      }
      {hit.relativeUrl &&
        <div className="flex mt-3 w-full">
          {hit_icons.length > 0 &&
            <HitIcons hit_icons={hit_icons} title={hit.title} />
          }
          <Link to={hit.relativeUrl} className="ml-auto">
            <Button title="Go to Page">Go to Page</Button>
          </Link>
        </div>
      }
      <hr className="mt-3 w-full underline" />
    </div>
  )
}

export default Hit

export {
  getIcon
}
