import React, {
  FC,
  ReactNode,
  useEffect,
  useState
} from 'react'

import { Link } from 'gatsby'

import data from '../../json/navigation.json'

import { formatClassList } from '@bscs-dev-team/bscs-design-system-common'

import {
  BottomNav,
  Button,
  Footer,
  Header,
  NavigationLg,
  Tagline
} from '@bscs-dev-team/bscs-design-system-core'

import AlgoliaInstantSearch from '../algolia-instant-search'

import '@fortawesome/fontawesome-pro/css/all.min.css'
import 'typeface-lora'
import 'font-proxima-nova/style.css'


type LayoutProps = {
  children: ReactNode
}

const WRAPPER: string = `
  pt-10
`

const BOTTOM_NAV_SEARCH_ICON: string = `
  fa-search
  fas
  text-bscs-indigo-800
`

const BOTTOM_NAV_SEARCH_TEXT: string = `
  block
  font-sans
  text-bscs-indigo-900
  text-sm
  tracking-wider
`

const FLEX_WRAPPER: string = `
  bg-bscs-gray-200
  flex
  justify-center
  shadow-inner
`

const CONTENT_AREA: string = `
  bg-bscs-gray-200
  w-full
`

const FOOTER: string = `
  lg:mb-0
  mb-4
`

const HEADER_SEARCH_BUTTON: string = `
  mr-3
`

const HEADER_SEARCH_ICON: string = `
  fa-search
  fas
  self-center
  text-bscs-indigo-800
`

const SHOPPING_BUTTON: string = `
  mr-3
  outline-none
  snipcart-checkout
`

const SHOPPING_ICON: string = `
  fa-shopping-bag
  fas
  text-bscs-indigo-800
`

const SNIPCART_SUMMARY: string = `
  snipcart-summary
`

const SNIPCART_TOTAL_ITEMS: string = `
  snipcart-items-count
`


const Layout:FC<LayoutProps> = ({ children } : LayoutProps) => {
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [manuallyCloseMenu, setManuallyCloseMenu] = useState<boolean>(false)

  const formattedBottomNavSearchIcon: string = formatClassList(BOTTOM_NAV_SEARCH_ICON)
  const formattedBottomNavSearchText: string = formatClassList(BOTTOM_NAV_SEARCH_TEXT)
  const formattedContentArea: string = formatClassList(CONTENT_AREA)
  const formattedFlexWrapper: string = formatClassList(FLEX_WRAPPER)
  const formattedFooter: string = formatClassList(FOOTER)
  const formattedHeaderSearchButton: string = formatClassList(HEADER_SEARCH_BUTTON)
  const formattedHeaderSearchIcon: string = formatClassList(HEADER_SEARCH_ICON)
  const formattedShoppingButton: string = formatClassList(SHOPPING_BUTTON)
  const formattedShoppingIcon: string = formatClassList(SHOPPING_ICON)
  const formattedSnipcartSummary: string = formatClassList(SNIPCART_SUMMARY)
  const formattedSnipcartTotalItems: string = formatClassList(SNIPCART_TOTAL_ITEMS)
  const formattedWrapper: string = formatClassList(WRAPPER)

  useEffect(() => {
    if (manuallyCloseMenu) {
      setManuallyCloseMenu(false)
    }
  }, [manuallyCloseMenu])

  return (
    <div id='layout-wrapper'>
      <div>
        <Header
          frameworkLinkComponent={Link}
          iconLink={{
            'to': '/'
          }}
          closeMenu={setManuallyCloseMenu}
          render={
            <>
              {/*id 'search-icon' is key for the onClick. Not sure why.*/}
              <Button
                className={formattedHeaderSearchButton}
                id='search-icon'
                onClick={() => setShowSearch(true)}
                title='Search'
                variant='naked'
              >
                Search&nbsp;
                <i
                  className={formattedHeaderSearchIcon}
                />
              </Button>
              <span className={formattedSnipcartSummary}>
                <Button variant='naked' className={formattedShoppingButton} title='Checkout'>
                  Shopping Bag&nbsp;<i className={formattedShoppingIcon} />&nbsp;<span className={formattedSnipcartTotalItems} />
                </Button>
              </span>
              <Link to="/donate" title="Donate To Science R&D Programs - Teacher Instructional Materials">
                <Button
                  className="bg-bscs-violet-900"
                  title="Donate Now"
                  variant="violet"
                >
                  Donate Now
                </Button>
              </Link>
            </>
          }
        />
        <Tagline
          closeMenu={setManuallyCloseMenu}
          tagline='Transforming science education through research-driven innovation'
        />
        <NavigationLg
          blurId='content-area'
          data={data}
          frameworkLinkComponent={Link}
          manuallyCloseMenu={manuallyCloseMenu}
        />
        <div className={formattedWrapper}>
          <div className={formattedFlexWrapper}>
            <div
              id='content-area'
              className={formattedContentArea}
            >
              {children}
            </div>
          </div>
        </div>
        <Footer
          className={formattedFooter}
          data={data}
          frameworkLinkComponent={Link}
        />
      </div>
      <BottomNav
        data={data}
        frameworkLinkComponent={Link}
        render={
          <>
            <Link to="/donate" title="Donate To Science R&D Programs - Teacher Instructional Materials">
              <Button
                className="bg-bscs-violet-900"
                title="Donate Now"
                variant="naked"
              >
                <span className="text-gray-100 tracking-wider font-semibold">Donate Now</span>
              </Button>
            </Link>
            <button
              aria-label='Open search menu'
              onClick={() => setShowSearch(true)}
              title='Open search menu'
            >
              <i
                id='search-icon'
                className={formattedBottomNavSearchIcon}
              />
              <span className={formattedBottomNavSearchText}>Search</span>
            </button>
          </>
        }
      />
      <AlgoliaInstantSearch
        showSearch={showSearch}
        hideSearchCallback={() => setShowSearch(false)}
      />
    </div>
  )
}

export default Layout
