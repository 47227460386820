import React, { FC } from 'react'

import { connectInfiniteHits } from 'react-instantsearch-dom'

import { Alert, Button, ContentArea } from '@bscs-dev-team/bscs-design-system-core'

import Hit from '../algolia-hit'


type ShowMoreProps = {
  hasMore: boolean,
  refineNext: () => void
}


const ShowMore: FC<ShowMoreProps> = ({ hasMore, refineNext }: ShowMoreProps) => {
  return (
    <div className="flex justify-center">
      <Button
        className="hidden sm:block mt-5"
        disabled={!hasMore}
        onClick={refineNext}
        variant="indigo"
      >
        Show More
      </Button>
    </div>
  )
}

const AlgoliaInfiniteHits = ({
  hits,
  hasPrevious,
  refinePrevious,
  hasMore,
  refineNext
}) => {
  const hits_exist: boolean = hits.length > 0

  if (hits_exist) {
    return (
      <ContentArea id="hits" size="xl" className="mt-5">
        <div>
          {hits.map((hit: any, index: number) => {
            return (
              <Hit key={`search-hit-${index}`} hit={hit} />
            )
          })}
        </div>
        <ShowMore
          hasMore={hasMore}
          refineNext={refineNext}
        />
      </ContentArea>
    )
  }

  return (
    <Alert
      className="mt-4"
      variant="yellow"
    >
      No results
    </Alert>
  )
}

export default connectInfiniteHits(AlgoliaInfiniteHits)
