import React, { FC, useState, useRef, useEffect } from 'react'

import {
  Configure,
  InstantSearch,
} from 'react-instantsearch-dom'

import algoliasearch from 'algoliasearch'

import AlgoliaInput from '../algolia-input'
import AlgoliaHits from '../algolia-hits'

import { formatClassList, joinStrings } from '@bscs-dev-team/bscs-design-system-common'
import { Alert } from '@bscs-dev-team/bscs-design-system-core'

import searchByAlgolia from '../../images/search-by-algolia-light-background.svg'


type AlgoliaInstantSearchProps = {
  hideSearchCallback: () => void,
  showSearch: boolean
}

const ALERT: string = `
  fixed-width-search-items
  m-2
  mt-4
  mx-auto
  text-center
`

const CLOSE: string = `
  absolute
  bg-bscs-gray-200
  duration-300
  ease-in-out
  p-2
  right-0
  text-2xl
  top-0
  transition-all
`

const CLOSE_ICON: string = `
  cursor-pointer
  fa-times
  fas
  text-2xl
  text-bscs-gray-600
`

const HR: string = `
  mb-0
`

const SEARCH_BY_ALGOLIA: string = `
  flex
  justify-end
`

//  fixed-width-search-items
const SEARCH_BY_ALGOLIA_WRAPPER: string = `
  mr-5
  pt-3
`

//  fixed-width-search-items
const SEARCH_BOX: string = `
  mt-16
  searchbox
`

const WRAPPER: string = `
  bg-bscs-gray-200
  duration-300
  ease-in-out
  fixed
  h-screen
  inset-0
  overflow-y-auto
  p-5
  transition-all
  z-50
`

const handleEscape = (
    e: KeyboardEvent,
    hideSearchCallback: () => void
) => {
  if (e.key === 'Escape') {
    e.preventDefault()

    hideSearchCallback()
  }
}

const search_client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const AlgoliaInstantSearch: FC<AlgoliaInstantSearchProps> = ({
  showSearch,
  hideSearchCallback
}: AlgoliaInstantSearchProps) => {
  const nodeRef = useRef<HTMLDivElement>(null)
  const closeRef = useRef<HTMLButtonElement>(null)

  const [search_input_exists, setSearchInputExists] = useState<boolean>(false)

  const formattedAlert: string = formatClassList(ALERT)
  const formattedClose: string = formatClassList(CLOSE)
  const formattedCloseIcon: string = formatClassList(CLOSE_ICON)
  const formattedHr: string = formatClassList(HR)
  const formattedSearchBox: string = formatClassList(SEARCH_BOX)
  const formattedSearchByAlgolia: string = formatClassList(SEARCH_BY_ALGOLIA)
  const formattedSearchByAlgoliaWrapper: string = formatClassList(SEARCH_BY_ALGOLIA_WRAPPER)
  const formattedWrapper: string = formatClassList(WRAPPER)

  const handleChange = (search_value: string): void => {
    search_value === ''
      ? setSearchInputExists(false)
      : setSearchInputExists(true)
  }

  useEffect(() => {
    document.addEventListener(
      'keydown',
      (e: KeyboardEvent) => handleEscape(e, hideSearchCallback),
      false
    )
    return () => {
      document.removeEventListener(
        'keydown',
        (e: KeyboardEvent) => handleEscape(e, hideSearchCallback),
        false
      )
    }

  }, [])

  return (
    <div
      className={formattedWrapper}
      style={
        showSearch
          ? { left: '0vw' }
          : { left: '100vw' }
      }
      ref={(node) => nodeRef.current = node}
    >
      <InstantSearch
        indexName="dev_BMW"
        searchClient={search_client}
      >
        <div className={formattedSearchBox}>
          <AlgoliaInput
            handleChangeCallback={(search_value: string) => handleChange(search_value)}
            id="search-box"
          />
        </div>
        <div className={formattedSearchByAlgoliaWrapper}>
          <div className={formattedSearchByAlgolia}>
            <img
              alt="Search by Algolia"
              src={searchByAlgolia}
              style={{
                width: "165px"
              }}
            />
          </div>
          <hr className={formattedHr} />
        </div>
        {search_input_exists &&
          <div>
            <Configure hitsPerPage={5} />
            <AlgoliaHits id="hits" />
            {/* <AlgoliaPagination /> */}
          </div>
        }
        {!search_input_exists &&
          <Alert
            className={formattedAlert}
            variant="indigo"
          >
            Start typing to see search results
          </Alert>
        }
      </InstantSearch>
      <button
        aria-label='Close Search Menu'
        className={showSearch
          ? formattedClose
          : joinStrings(' ', formattedClose, '-mr-64')
        }
        onClick={hideSearchCallback}
        ref={closeRef}
        title='Close Search Menu'
      >
        <i className={formattedCloseIcon} />
      </button>
    </div>
  )
}

export default AlgoliaInstantSearch

