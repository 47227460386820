import React, { FC } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'

import { SearchInputField } from '@bscs-dev-team/bscs-design-system-add-ons'


type AlgoliaInputProps = {
  id: string,
  refine: (value: string) => void,
  handleChangeCallback: (value: string) => void
}

const AlgoliaInput: FC<AlgoliaInputProps> = ({
  id,
  refine,
  handleChangeCallback
}) => {
  return (
    <SearchInputField
      id={id}
      name="Search"
      setValue={(value: string) => {
        refine(value)
        handleChangeCallback(value)
      }}
    />
  )
}

export default connectSearchBox(AlgoliaInput)
